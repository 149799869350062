import {
    ORGS_LOADED,
    ORGS_PAGE_CHANGED,
    ORGS_PER_PAGE_CHANGED,
    ORGS_FORM_FIELD_CHANGED,
    ORGS_FORM_CLEAR,
    ORGS_FORM_EDIT,
    USERS_FORM_FIELD_CHANGED,
    USERS_FORM_CLEAR,
    USERS_FORM_EDIT,
    RESTRICTED_MEETS,
    DASHBOARD_COLOR_PICKER,
    DASHBOARD_IMAGE,
    LOGO_IMG,
    DASHBOARD_COLOR_PICKER_APP,
    LOGO_APPLICATION,
    CLEAR_CUSTOM_THEME,
    OPACITY_BG,
    USERS_FORM_FIELD_CHANGED_PASSWORD,
    CHANGE_PASSWORD_FORM_CLEAR,
    DASHBOARD_COLOR_PICKER_TEXT,
    DASHBOARD_COLOR_PICKER_BG,
    SELECT_COLOR,
    DASHBOARD_IMAGE_APP,
    SELECT_COLOR_APP,
    CLEAR_CUSTOM_THEME_APP,
    CUSTOM_LOAD_NEW_STATE,
    APP_BG_IMAGE_AS_PREJOIN,
    CLEAR_CUSTOM_THEME_ALL,
    USER_ROLES_LIST,
    ERROR_MESSAGE,
    LOCAL_ENABLED_CHANGE,
    SERVER_ENABLED_CHANGE,
    SERVER_LIMIT_VALUE,
    SET_RECORDING_FILE_SIZE_ON_SERVER
} from './actionTypes';

const usersState = {
    orgs: [],
    page: 0,
    orgsPerPage: 10,
    fileSize: 0,
    orgsForm: {
        name: '',
        participants: 0,
        meetsPerTime: 1000,
        licenses: [],
        exp: '01.01.2070',
        palette: 1,
        bindDN: '',
        domain: '',
        errorMessage: false,
        recordingLimit: {
            localEnabled: true,
            serverEnabled: true,
            serverLimitValue: 0
        }
    },
    form: {
        username: '',
        password: '',
        passwordConfirm: '',
        active: true,
        name: '',
        email: '',
        phone: '',
        isSecretary: true,
        allowArchive: false,
        restrictedMeets: false,
        role: 'secretary'
    },

    changePassword: {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: ''
    },
    paletteSettings: {
        prejoinInfoBgColor: 'rgba(245,245,245,1)',
        prejoinBgColor: 'rgba(54,58,72,1)',
        selectColor: true,
        selectColorApp: true,
        prejoinBgImage: {
            image: null,
            file: null,
            url: null
        },
        appBgImage: {
            image: null,
            file: null,
            url: null
        },
        prejoinLogo: {
            image: null,
            file: null,
            url: null
        },
        appBgColor: 'rgba(60,58,66,1)',
        appLogo: {
            image: null,
            file: null,
            url: null
        },
        appBgImageAsPrejoin: false,
        opacityBg: false,
        prejoinInfoFontColor: 'rgba(0,0,0,0.87)'
    }
};

export function usersReducer(state = usersState, action) {
    switch (action.type) {
        case ORGS_LOADED:
            return {
                ...state,
                orgs: action.payload.orgs
            };
        case ORGS_PAGE_CHANGED:
            return { ...state, page: action.payload.page };

        case ORGS_PER_PAGE_CHANGED:
            return {
                ...state,
                page: action.payload.page,
                orgsPerPage: action.payload.orgsPerPage
            };

        case ORGS_FORM_FIELD_CHANGED:
            return {
                ...state,
                orgsForm: {
                    ...state.orgsForm,
                    [action.payload.field]: action.payload.value
                }
            };
        case LOCAL_ENABLED_CHANGE: {
            return {
                ...state,
                orgsForm: {
                    ...state.orgsForm,
                    recordingLimit: {
                        ...state.orgsForm.recordingLimit,
                        localEnabled: action.payload
                    }
                }
            };
        }

        case SERVER_ENABLED_CHANGE: {
            return {
                ...state,
                orgsForm: {
                    ...state.orgsForm,
                    recordingLimit: {
                        ...state.orgsForm.recordingLimit,
                        serverEnabled: action.payload
                    }
                }
            };
        }
        case ORGS_FORM_CLEAR:
            return { ...state, orgsForm: usersState.orgsForm };

        case ORGS_FORM_EDIT:
            return {
                ...state,
                orgsForm: {
                    name: action.payload.org.name,
                    participants: action.payload.org.participants,
                    meetsPerTime: action.payload.org.meetsPerTime,
                    licenses: action.payload.org.licenses,
                    exp: action.payload.org.exp,
                    bindDN: action.payload.org.bindDN,
                    domain: action.payload.org.domain,
                    errorMessage: usersState.orgsForm.errorMessage,
                    recordingLimit:
                        action.payload.org.recordingLimit !== undefined
                            ? {
                                localEnabled:
                                    action.payload.org.recordingLimit
                                        .localEnabled,
                                serverEnabled:
                                    action.payload.org.recordingLimit
                                        .serverEnabled,
                                serverLimitValue:
                                    action.payload.org.recordingLimit
                                        .serverLimitValue /
                                    1000 /
                                    1000 /
                                    1000
                            }
                            : undefined
                }
            };

        case SERVER_LIMIT_VALUE: {
            return {
                ...state,
                orgsForm: {
                    ...state.orgsForm,
                    recordingLimit: {
                        ...state.orgsForm.recordingLimit,
                        serverLimitValue: action.payload
                    }
                }
            };
        }

        case USERS_FORM_FIELD_CHANGED:
            return {
                ...state,
                form: {
                    ...state.form,
                    [action.payload.field]: action.payload.value
                }
            };
        case USERS_FORM_FIELD_CHANGED_PASSWORD:
            return {
                ...state,
                changePassword: {
                    ...state.changePassword,
                    [action.payload.field]: action.payload.value
                }
            };
        case RESTRICTED_MEETS:
            return {
                ...state,
                form: {
                    ...state.form,
                    [action.payload.field]: action.payload.value
                }
            };
        case APP_BG_IMAGE_AS_PREJOIN:
            return {
                ...state,
                paletteSettings: {
                    ...state.paletteSettings,
                    appBgImageAsPrejoin: action.payload
                }
            };
        case USER_ROLES_LIST:
            return { ...state, form: { ...state.form, role: action.payload } };
        case USERS_FORM_CLEAR:
            return { ...state, form: usersState.form };
        case CHANGE_PASSWORD_FORM_CLEAR:
            return { ...state, changePassword: usersState.changePassword };
        case USERS_FORM_EDIT:
            return {
                ...state,
                form: {
                    ...state.form,
                    username: action.payload.user.username,
                    active: action.payload.user.active,
                    name: action.payload.user.name,
                    email: action.payload.user.email,
                    phone: action.payload.user.phone,
                    isSecretary: action.payload.user.isSecretary,
                    allowArchive: action.payload.user.allowArchive,
                    restrictedMeets: action.payload.user.restrictedMeets
                }
            };
        case DASHBOARD_COLOR_PICKER:
            return {
                ...state,
                paletteSettings: {
                    ...state.paletteSettings,
                    prejoinInfoBgColor: action.payload
                }
            };
        case ERROR_MESSAGE:
            return {
                ...state,
                orgsForm: {
                    ...state.orgsForm,
                    errorMessage: action.payload
                }
            };
        case DASHBOARD_COLOR_PICKER_TEXT:
            return {
                ...state,
                paletteSettings: {
                    ...state.paletteSettings,
                    prejoinInfoFontColor: action.payload
                }
            };
        case DASHBOARD_COLOR_PICKER_BG:
            return {
                ...state,
                paletteSettings: {
                    ...state.paletteSettings,
                    prejoinBgColor: action.payload
                }
            };
        case DASHBOARD_COLOR_PICKER_APP:
            return {
                ...state,
                paletteSettings: {
                    ...state.paletteSettings,
                    appBgColor: action.payload
                }
            };
        case DASHBOARD_IMAGE:
            return {
                ...state,
                paletteSettings: {
                    ...state.paletteSettings,
                    prejoinBgImage: {
                        image: action.payload.image,
                        file: action.payload.file
                    }
                }
            };
        case DASHBOARD_IMAGE_APP:
            return {
                ...state,
                paletteSettings: {
                    ...state.paletteSettings,
                    appBgImage: {
                        image: action.payload.image,
                        file: action.payload.file
                    }
                }
            };
        case LOGO_IMG:
            return {
                ...state,
                paletteSettings: {
                    ...state.paletteSettings,
                    prejoinLogo: {
                        image: action.payload.image,
                        file: action.payload.file
                    }
                }
            };
        case LOGO_APPLICATION:
            return {
                ...state,
                paletteSettings: {
                    ...state.paletteSettings,
                    appLogo: action.payload
                }
            };
        case CLEAR_CUSTOM_THEME:
            return {
                ...state,
                paletteSettings: {
                    ...state.paletteSettings,
                    prejoinInfoBgColor:
                        usersState.paletteSettings.prejoinInfoBgColor,
                    prejoinBgColor: usersState.paletteSettings.prejoinBgColor,
                    selectColor: usersState.paletteSettings.selectColor,
                    prejoinBgImage: {
                        ...usersState.paletteSettings.prejoinBgImage
                    },
                    prejoinLogo: {
                        ...usersState.paletteSettings.prejoinLogo
                    },
                    opacityBg: usersState.paletteSettings.opacityBg,
                    prejoinInfoFontColor:
                        usersState.paletteSettings.prejoinInfoFontColor
                }
            };
        case CLEAR_CUSTOM_THEME_ALL:
            return {
                ...state,
                paletteSettings: {
                    ...usersState.paletteSettings
                    // appBgImageAsPrejoin: state.paletteSettings.appBgImageAsPrejoin
                }
            };
        case CLEAR_CUSTOM_THEME_APP:
            return {
                ...state,
                paletteSettings: {
                    ...state.paletteSettings,
                    selectColorApp: usersState.paletteSettings.selectColorApp,
                    appBgImage: {
                        ...usersState.paletteSettings.appBgImage
                    },

                    appBgColor: usersState.paletteSettings.appBgColor,
                    appLogo: {
                        ...usersState.paletteSettings.appLogo
                    },
                    appBgImageAsPrejoin:
                        usersState.paletteSettings.appBgImageAsPrejoin
                }
            };
        case CUSTOM_LOAD_NEW_STATE: {
            const {
                prejoinInfoBgColor,
                prejoinBgColor,
                prejoinBgImage,
                appBgImage,
                prejoinLogo,
                appBgColor,
                appLogo,
                prejoinInfoFontColor
            } = action.payload;
            return {
                ...state,
                paletteSettings: {
                    ...state.paletteSettings,
                    prejoinInfoBgColor: prejoinInfoBgColor,
                    prejoinBgColor: prejoinBgColor,
                    prejoinBgImage: {
                        url: prejoinBgImage
                    },
                    appBgImage: {
                        url: appBgImage
                    },
                    prejoinLogo: {
                        url: prejoinLogo
                    },
                    appBgColor: appBgColor,
                    appLogo: {
                        url: appLogo
                    },
                    prejoinInfoFontColor: prejoinInfoFontColor
                }
            };
        }
        case OPACITY_BG:
            return {
                ...state,
                paletteSettings: {
                    ...state.paletteSettings,
                    opacityBg: action.payload
                }
            };
        case SELECT_COLOR:
            return {
                ...state,
                paletteSettings: {
                    ...state.paletteSettings,
                    selectColor: action.payload
                }
            };

        case SELECT_COLOR_APP:
            return {
                ...state,
                paletteSettings: {
                    ...state.paletteSettings,
                    selectColorApp: action.payload
                }
            };
        case SET_RECORDING_FILE_SIZE_ON_SERVER:
            return {
                ...state,
                fileSize: action.payload
            };
        default:
            return state;
    }
}

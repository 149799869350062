export const filterMeets = (
    meetsList,
    meetsFilter,
    isRooms,
    isPastMeets,
    isAllMeets,
    isAdmin
) => {
    let _meetsList = meetsList;

    // if (meetsFilter.recordings && isPastMeets)
    //     _meetsList = _meetsList.filter((meet) => meet.recordings.length > 0);

    if (meetsFilter.types.length > 0 && !isRooms) {
        _meetsList = _meetsList.filter((meet) => {
            if (
                meetsFilter.types.length === 1 &&
                meetsFilter.types[0] === 'room' &&
                !isPastMeets
            )
                return true;

            if (
                meetsFilter.types.includes('meet') &&
                !meet.periodic &&
                !meet.room
            )
                return true;

            if (meetsFilter.types.includes('periodic') && meet.periodic)
                return true;

            if (meetsFilter.types.includes('room') && meet.room) return true;
        });
    }

    if (meetsFilter.orgs.length > 0) {
        _meetsList = _meetsList.filter((meet) =>
            meetsFilter.orgs.includes(isAdmin ? meet.org?._id : meet.org)
        );
    }

    if (meetsFilter.users.length > 0) {
        _meetsList = _meetsList.filter((meet) =>
            meetsFilter.users.includes(isAdmin ? meet.user?._id : meet.user)
        );
    }

    if (isAllMeets && meetsFilter.startDate && meetsFilter.endDate) {
        _meetsList = _meetsList.filter((meet) => {
            if (
                ((!isPastMeets && !meet.periodic) || isPastMeets) &&
                meet.date >= meetsFilter.startDate &&
                meet.date <= meetsFilter.endDate
            ) {
                return true;
            }

            if (!isPastMeets && meet.periodic) {
                if (meetsFilter.days.length >= 7) return true;

                for (const day of meetsFilter.days) {
                    if (meet.daysOfWeek[day]) return true;
                }
            }
        });
    }

    return _meetsList;
};

export const showClearButton = (
    meetsFilter,
    isRooms,
    isPastMeets,
    isAllMeets
) => {
    if (meetsFilter.orgs.length > 0 || meetsFilter.users.length > 0) {
        return true;
    }

    // if (
    //     isPastMeets &&
    //     (meetsFilter.types.length > 0 || meetsFilter.recordings)
    // ) {
    //     return true;
    // }

    if (
        !isRooms &&
        (meetsFilter.types.length > 1 ||
            (meetsFilter.types.length === 1 &&
                !meetsFilter.types.includes('room')))
    ) {
        return true;
    }

    if (isAllMeets && (meetsFilter.startDate || meetsFilter.endDate)) {
        return true;
    }

    return false;
};

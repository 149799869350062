import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { showModal } from '../../../../actions';
import { ArchiveTwoTone } from '@mui/icons-material';
import Action from '../../../../Action';
import FileManager from '../../../file_manager/FileManager';
import { fileManagerClear } from '../../../file_manager/actions'

function GetRecs({ meet }) {
    const { _settings, _user } = useSelector(state => {
        return {
            _settings: state.app.settings,
            _user: state.app.user
        }
    })

    const dispatch = useDispatch()
    if (_settings.restrictedArchive && (!_user.isAdmin && !_user.allowArchive))
        return null;
    const { _id: meetId } = meet
    return (
        <Action
            title={'meetsList.download'}
            icon={<ArchiveTwoTone />}
            onClickHandle={
                () => {
                    dispatch(fileManagerClear())
                    dispatch(
                        showModal({
                            title: 'archive.title',
                            form: <FileManager meetId={meetId} />,
                            actionButton: '',
                            action: {},
                            closeAction: {
                                action: fileManagerClear()
                            },
                            sizeDialog: 'lg'
                        })
                    )
                }
            }
        >
        </Action>
    )
}

export default withTranslation()(GetRecs);

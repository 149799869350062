import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { meetsFilterItemSelected } from './actions';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListSubheader
} from '@mui/material';
import { meetsVisibleRecords } from '../actions';



function OrgsUsers({ t }) {

    const { _orgsMap, _selectedOrgs, _selectedUsers, _isAdmin } = useSelector(state => {
        return {
            _orgsMap: state.app.settings.orgsMap,
            _selectedOrgs: state.meetsFilter.orgs,
            _selectedUsers: state.meetsFilter.users,
            _isAdmin: state.app.user.isAdmin,
            _meets: state.meets
        }
    });

    const dispatch = useDispatch();
    function renderOrgsItems() {
        const orgsItems = [];
        for (const orgId of Object.keys(_orgsMap)) {
            orgsItems.push(
                <MenuItem
                    key={orgId}
                    value={orgId}
                >
                    {_orgsMap[orgId].name}
                </MenuItem>
            )
        }

        return orgsItems;
    }


    function renderUsersItems() {
        const usersItems = [];
        for (const orgId of Object.keys(_orgsMap)) {
            if (_selectedOrgs.length !== 0 && !_selectedOrgs.includes(orgId))
                continue;

            const org = _orgsMap[orgId];

            if (_isAdmin)
                usersItems.push(
                    <ListSubheader key={orgId} color="primary" >{org.name}</ListSubheader>
                );

            for (const userId of Object.keys(org.users)) {
                usersItems.push(
                    <MenuItem
                        key={userId}
                        value={userId}
                    >
                        {org.users[userId]}
                    </MenuItem>
                )
            }
        }

        return usersItems;
    }

    return (
        <>
            {_isAdmin ?
                <FormControl
                    variant="outlined"
                    size="small"
                    margin="dense"
                    className="select"
                >
                    <InputLabel id="orgs">{t('meetsFilter.orgs')}</InputLabel>
                    <Select
                        label={t('meetsFilter.orgs')}
                        labelId="orgs"
                        multiple
                        name="orgs"
                        value={_selectedOrgs}
                        onChange={event => {
                            dispatch(meetsFilterItemSelected(event.target.name, event.target.value));
                            dispatch(meetsVisibleRecords());
                        }}
                    >
                        {renderOrgsItems()}
                    </Select>
                </FormControl>
                :
                null
            }
            <FormControl
                variant="outlined"
                size="small"
                margin="dense"
                className="select"
            >
                <InputLabel id="users">{t('meetsFilter.users')}</InputLabel>
                <Select
                    label={t('meetsFilter.users')}
                    labelId="users"
                    multiple
                    name="users"
                    value={_selectedUsers}
                    onChange={event => {
                        dispatch(meetsFilterItemSelected(event.target.name, event.target.value));
                        dispatch(meetsVisibleRecords());
                    }}
                >
                    {renderUsersItems()}
                </Select>
            </FormControl>
        </>
    )

}


export default withTranslation()(OrgsUsers);

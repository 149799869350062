const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    transition: 'border .3s ease-in-out',
    width: '100%',
    height: '13rem',
    fontSize: '0.9rem',
    textAlign: 'center',
    cursor: 'pointer'
};

const image = {
    width: '100%',
    height: '13rem',
    objectFit: 'cover',
    display: 'block',
    position: 'relative',
    border: '1px solid rgb(238, 238, 238)',
    backgroundColor: 'rgb(250, 250, 250)'
};
const imageLogo = {
    width: '100%',
    height: '13rem',
    objectFit: 'contain',
    display: 'block',
    position: 'relative',
    border: '1px solid rgb(238, 238, 238)'
};
const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#1976d2'
};

const rejectStyle = {
    borderColor: '#ff1744'
};
const button = {
    position: 'absolute',
    top: '0',
    right: '0'
};
const USER_PERMS = {
    LICENSES: 'licenses',
    ORGS: 'orgs',
    ORG: 'org',
    ORG_ADD: 'org_add',
    ORG_DELETE: 'org_delete',
    ORG_EDIT: 'org_edit',
    THEME: 'theme',
    USERS: 'users',
    USER_ADD: 'user_add',
    USER_DELETE: 'user_delete',
    USER_EDIT: 'user_edit',
    SETTINGS: 'settings',
    FILES: 'files'
};
const USER_DEFAULT_ADMIN_NAME = 'admin';

export {
    baseStyle,
    image,
    imageLogo,
    activeStyle,
    acceptStyle,
    rejectStyle,
    button,
    USER_PERMS,
    USER_DEFAULT_ADMIN_NAME
};
